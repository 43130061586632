<template>
    <div>
        <loader
            :loading="loading"
            :backdrop="true"
        />

        <div
            v-if="media"
            class="w-full h-90"
        >
            <image-editor
                v-if="media && media.base64"
                :image="media.base64"
                @image-cropped="cropImage"
            />
            <div v-else>
                <span class="text-red-500">Could not return the image bas64 from the api.</span>
            </div>
        </div>
        <div class="absolute bottom-0 left-0 w-full px-8 py-6 flex justify-between">
            <button
                class="btn-primary btn-transparent"
                :disabled="loading"
                @click="close"
            >
                cancel
            </button>
            <button
                v-if="media && media.base64"
                class="btn-primary btn-solid"
                :disabled="loading"
                @click="edit"
            >
                edit
            </button>
        </div>
    </div>
</template>

<script>
import Auth from '@/mixins/Auth';
import Loader from '@/components/ui/Loader';
import NotifyMixin from '@/mixins/NotifyMixin';
import ModalNavigation from '@/mixins/ModalNavigation';
import ImageEditor from '@/components/ui/filemanager/ImageEditor';

export default {
    components: { ImageEditor, Loader },
    mixins: [ModalNavigation, Auth, NotifyMixin],
    data: () => {
        return {
            media: null,
            loading: false,
        };
    },

    mounted() {
        this.getMedia();
    },

    methods: {
        cropImage(payload) {
            window.console.log(payload.image);
        },

        getMedia() {
            this.loading = true;
            this.$filemanagerDataProvider
                .get('fileInfo', { fileId: this.$route.params.id })
                .then(response => {
                    this.media = response;
                    window.resize();
                })
                .catch(() => {
                    this.notifyError('Error while fetching media.');
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        edit() {
            // filemanager update api to replace image
        },
    },
};
</script>

<style scoped>
.h-90 {
    height: 90%;
}
</style>
